// App.js
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css'; // Importing the CSS file to style the components

function App() {
  return (
    <Routes>
      {/* Home Route */}
      <Route path="/" element={<HomeRedirect />} />

      {/* Other Routes */}
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      {/* Add more routes as needed */}

      {/* Catch-All Route (Optional): Handle 404 Not Found */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
